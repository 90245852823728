<template>
    <div class="container_con">
        <el-card>
            <div class="new_search_box">
              <div style="margin-top:0px">
                  <span class="mr_20">
                    编号：
                    <el-input
                      v-model="queryParams.purchase_acceptance_no"
                      placeholder="请输入编号"
                      style="width:200px"
                    />
                  </span>
                  <span class="mr_20">
                    供货单位：
                    <el-select v-model="queryParams.merchant_id" placeholder="输入供货单位名称或助记码" clearable remote :remote-method="remoteMethod"
                        style="width:220px" filterable>
                        <el-option :label="item.merchant_name" v-for="(item,index) in jhdwlist"
                            :key="index" :value="item.id"></el-option>
                    </el-select>
                  </span>
                  <span class="mr_20">
                    时间：
                    <el-date-picker
                      v-model="queryParams.time"
                      type="daterange"  value-format="YYYY-MM-DD" 
                      placeholder="请选择时间"
                      range-separator="至" style="width: 300px;"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </span>
                <el-button type="primary" @click="handleQuery()">搜索</el-button>
                <el-button @click="resetQuery()">重置</el-button>
              </div>
            </div>
            <div class="search">
                <div>
                    <el-button type="primary" @click="try_sele" v-if="have_auth('/purchaseAccept/add')">选择采购计划单</el-button>
                    <el-button type="primary" icon="el-icon-printer" :disabled="multiple" @click="try_print" v-if="have_auth('/purchaseAccept/print')">打印</el-button>
                    <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/purchaseAccept/export')" :loading="is_export" @click="try_export">导出</el-button>
                </div>
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column prop="purchase_acceptance_date" label="开票时间" />
                    <el-table-column prop="purchase_acceptance_no" label="单据编号" />
                    <el-table-column prop="department_name" label="部门" />
                    <el-table-column prop="admin_name" label="验收员" />
                    <el-table-column prop="merchant_no" label="单位编号" />
                    <el-table-column prop="merchant_name" label="供货单位" />
                    <el-table-column prop="amount" label="整单金额" />
                    <el-table-column fixed="right" label="操作" width="150">
                        <template v-slot="scope">
                            <el-button type="text" size="small" @click="handleDetail(scope.row)" v-if="have_auth('/purchaseAccept/view')">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    />
    <selectpurchasePlan ref="selectpurchasePlan" @after_sele="after_selectpurchasePlan" />
    <print ref="print" />
</template>

<script>
    import selectpurchasePlan from "./selectpurchasePlan";
    // 打印
    import print from "@/components/print";
    export default {
        name: "department",
        components: {selectpurchasePlan,print},
        data() {
            return {
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    purchase_acceptance_no: '',
                    time:''
                },
                total:0,
                list:[],
                
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                is_export:false,
                jhdwlist:[],
            };
        },
        created() {
            this.queryParams=window[this.$route.path] || {
              page: 1,
              size: 10,
              purchase_acceptance_no: '',
              time:''
            }
            this.getList();
            this.remoteMethod()
        },
        watch: {
          $route(to,form) {
              window[form.path]=this.queryParams
          },
        },
        methods: {
            resetQuery(){
                this.queryParams= {
                    page: 1,
                    size: 10,
                    purchase_acceptance_no: '',
                    time:''
                }
                this.getList()
            },
            remoteMethod(keyword){
                this.$httpGet("/backend/Merchant/merchant_purchase", {size:10000,title:keyword}).then((res) => {
                    if (res.status == 200) {
                        this.jhdwlist = res.data.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            try_export(){
                if(this.is_export){return false;}
                this.is_export = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/PurchaseAcceptance/export", _data).then((res) => {
                    if (res.status == 200) {
                          var a = document.createElement('a');
                          let name=res.data.file.split('/')
                          var fileName = name[name.length-1];
                          a.download = fileName;
                          a.href = this.$http+'/'+res.data.file;
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_export = false;
                }).catch((err) => {
                    console.log(err);
                    this.is_export = false;
                });
            },
            try_print(){
                this.$refs.print.init(this.ids,2)
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
              this.ids = selection.map(item => item.id);
              this.single = selection.length != 1;
              this.multiple = !selection.length;
            },
            // 查看详情
            handleDetail(row){
                this.$router.push('/purchaseAcceptDetail?id='+row.id)
            },
            after_selectpurchasePlan(arr){
                this.$router.push('/addpurchaseAccept?id='+arr[0].id)
            },
            try_sele(){
                this.$refs['selectpurchasePlan'].init()
            },
            
            /** 查询列表 */
            getList() {
                this.loading = true;
                let _data={...this.queryParams};
                _data.startTime=_data.time?_data.time[0]:'';
                _data.endTime=_data.time?_data.time[1]:'';
                this.$httpGet("/backend/PurchaseAcceptance/index", _data).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
